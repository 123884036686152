.activity {
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.hovering_activity {
  @extend .activity;
  background-color: rgb(214, 214, 214);
}

.selected_activity {
  @extend .activity;
  background-color: #0a1f5c3e;
}

.processing_activity {
  background-color: rgb(253, 251, 221);
  color: rgb(99, 99, 99);
  cursor: wait;
  @extend .activity;
}
