.avv_chip {
  border-radius: 10px;
  padding: 0.5rem;
  min-width: 75%;

  &.high {
    background-color: #c4ffbe;
  }

  &.medium {
    background-color: #fff0be;
  }

  &.low {
    background-color: #e6e6e6;
  }
}

.column_card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
}

.column_card_title {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.attribute_chip {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
