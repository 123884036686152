.divider {
  width: 5%;
  border-radius: 1rem;
  color: #6e6e6e;
}

.centered {
  margin: auto;
}

.enum_list {
  max-height: 200px;
  overflow: auto;
  padding-left: 4rem;
  width: 90%;
}

.flex_container {
  display: flex;
  align-items: center;
  justify-self: start;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.flex_container_width {
  width: 90%;
}

.flex_horizontal {
  flex-direction: row;
}

.flex_vertical {
  flex-direction: column;
}

.filter_panel_header {
  padding: 1rem 0rem 0rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
}
