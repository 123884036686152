.unregistered-user-cell {
  background-color: lightgray;
  color: gray;
}

.unregistered-user-row {
  position: relative;
}

.unregistered-user-row::before {
  position: absolute;
  left: 95%;
  top: 50%;
  text-align: center;
  content: 'Registrierung ausstehend';
  color: crimson;
  font-weight: bold;
  transform: translate(-50%, -50%);
}
